export const styles = {
  sectionCardContainer: {
    height: '60px',
    width: '200px',
    border: '5px solid #635b57',
    borderRadius: '20px',
    textAlign: 'center',
    paddingTop: '10px',
  }
}
